//GOOGLE FONTS

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;600;700&family=Sen:wght@400;700;800&display=swap");

// =================
// VARIABLES
// =================

$title-font: Sen, sans-serif;
$text-font: "Open Sans", sans-serif;

$total-black: #000000;
$off-black: #232323;
$medium-grey: #808080;
$light-grey: #dedede;
$white: #ffffff;
$off-white: #efefef;
$main-red: #b30000;
$light-red: #b35959;

$sm: 481px;
$lg: 768px;
$xl: 1025px;

// =================
// GLOBAL STYLES
// =================
html {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	white-space: normal ;
	font-family: $text-font;
	font-weight: 200;
	font-size: 0.8rem;
}

a,
a:active,
a:hover,
a:visited {
	color: inherit;
	text-decoration: inherit;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

li {
	text-decoration: none;
}

// p {
// 	margin-top: 0.5rem;
// 	margin-bottom: 0.5rem;
// 	margin-block-start: 0.5rem;
// 	margin-block-end: 0.5rem;
// }

//=================
// EYE CANDY
//=================
@media (min-width: $lg) {
	.reveal {
		opacity: 0;
		transition-delay: 2s;
		transition: opacity 0.8s ease-out, transform 1.4s ease-out;
	}

	.reveal.fadeInUp {
		transform: translateY(-20);
	}

	.reveal.fadeInUp.visible {
		transform: translateY(0);
		opacity: 1;
	}

	.reveal.fadeInRight {
		transform: translateX(60px);
	}

	.reveal.fadeInRight.visible {
		transform: translateX(0);
		opacity: 1;
	}

	.reveal.fadeInLeft {
		transform: translateX(-60px);
	}

	.reveal.fadeInLeft.visible {
		transform: translateX(0);
		opacity: 1;
	}

	.reveal.fadeInLeft--AboutBg {
		transform: translateX(-80px) translateY(-20px);
	}
	.reveal.fadeInLeft--AboutBg.visible {
		transform: translateX(-20px) translateY(-20px);
		opacity: 1;
	}

	.reveal.fadeIn {
	}

	.reveal.fadeIn.visible {
		opacity: 1;
	}
}
//=================
// SECTIONS
//=================

//LAYOUT
//BACKGROUND

.fake-background {
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	z-index: -100;
	//theme
	background-color: $white;
}

//NAVIGATION

.nav {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: $title-font;
	font-weight: 400;
	font-size: 1rem;
}

.nav__logo-box {
	margin: 5px 5px 5px 15px;
	display: flex;
	align-items: center;
}

.nav__logo {
	height: 50px;
}

.nav__burger-box {
	margin: 5px 15px 5px 5px;
	display: flex;
	align-items: center;
}

.nav__burger {
	height: 40px;
}

.nav__link-box {
	display: none;
	align-items: center;
}

.nav__item {
	display: block;
	margin-right: 10px;
	margin-top: 10px;
}

.nav__link {
	cursor: pointer;
	text-transform: uppercase;
	color: inherit;
}

.nav__sidebar {
	position: fixed;
	top: 0px;
	left: 0px;
	transform: translateX(-100%);
	height: 100vh;
	width: 100vw;
	transition: all 1s ease-out;
	z-index: 500;
	padding: 3rem;

	font-family: $title-font;
	font-weight: 400;
	font-size: 1rem;
}
.nav__sidebar--show {
	transform: translateX(0);
}

.nav__sidebar__link-box {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
}

.nav__sidebar__close-button {
	margin: 10px 10px 10px auto;
	font-weight: 700;
	width: 1.5em;
}

@media (min-width: $lg) {
	.nav__link-box {
		display: flex;
	}

	.nav__burger-box {
		display: none;
	}
}

//FOOTER

.footer {
	width: 100%;
	margin: 0;
	padding: 1rem 1rem 2rem 1rem;
	font-family: $title-font;
	font-weight: 400;
	font-size: 1rem;
	margin-bottom: -10rem;
}

.footer__link-box {
	display: flex;
	justify-content: space-around;
	text-transform: uppercase;
}

.footer__link__li {
	margin-bottom: 0.8rem;
}

.footer__copyright {
	font-size: 0.8rem;
	font-weight: 200;
}

//=================
//PAGES
//=================

.content {
	margin-top: 100px;
}
//INDEX

//INTRO

.intro {
	width: 100%;

	font-family: $text-font;
	font-weight: 200;
	font-size: 0.8rem;
	transition-delay: 1s;
}
.intro__hero {
	display: grid;
	margin-left: 3vw;

	grid-template-areas:
		"javi javi ."
		"de text text"
		"salas salas .";
}
.intro__hero__item {
	height: 30vw;
}

.intro__hero__item--javi {
	grid-area: javi;
}
.intro__hero__item--de {
	grid-area: de;
	margin-top: 10px;
}
.intro__hero__item--salas {
	grid-area: salas;
}
.intro__hero__text {
	grid-area: text;
	align-self: end;
	font-family: $title-font;
	font-weight: 400;
	font-size: 0.8rem;
	padding-bottom: 3vw;
}

.intro__description__text {
	padding: 0 8px;
	margin: 5px;
}

.intro__description__text strong {
	font-weight: 400;
}
.intro__description__link {
	font-weight: 400;
}
.intro_description__background {
	display: none;
}

@media (min-width: $lg) {
	.intro {
		margin: 10vh 0;
	}
	.intro__hero {
		grid-template-columns: auto auto minmax(100px, max-content) auto auto;
		grid-template-areas:
			". javi de salas ."
			". . text text text";
		margin-top: 12vw;
	}
	.intro__hero__item {
		height: 14vw;
		max-height: 170px;
	}
	.intro__hero__item--javi {
		justify-self: end;
	}
	.intro__hero__item--de {
		margin: 0 4vw;
		justify-self: center;
		max-width: 170px;
	}
	.intro__hero__item--salas {
		justify-self: start;
	}
	.intro__hero__text {
		width: 100%;
		padding: 5px 0 5px 10px;
		font-size: 1rem;
	}
	.intro__description {
		margin-top: 7vh;
		display: grid;
		grid-template-columns: 1fr 2fr 2fr;
		grid-template-rows: 1fr;
	}
	.intro__description__text {
		grid-column-start: 2;
		grid-row-start: 1;
		z-index: 5;
		margin: 0px;
		border: solid 1px;
	}
	.intro__description__background {
		display: block;
		grid-column-start: 1;
		grid-column-end: span 2;
		grid-row-start: 1;
		width: 100%;
		height: 100%;
		background: url(./pattern-white.svg) repeat;
		background-size: 30px;
		z-index: 3;
		transform: translateX(-10px) translateY(10px);
	}
}

// EXPERIENCE

.experience {
	width: 100%;
	font-family: $text-font;
	font-weight: 200;
	font-size: 0.8rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.experience__title {
	font-family: $title-font;
	text-transform: uppercase;
	width: 100%;
	padding: 10px 0;
	text-align: center;
}

.experience__header {
	display: flex;
	width: 100%;
	justify-content: center;
}

.experience__header__item {
	cursor: pointer;
	font-family: $title-font;
	text-transform: uppercase;
	margin: 5px 10px 5px 0px;
}

.experience__header__item::before {
	content: "> ";
}

.experience__viewer {
	overflow: hidden;
	width: 90vw;
}

.experience__viewer__container {
	display: grid;
	grid-template-columns: repeat(3, auto);
	transition: all 1s ease-out;
}

.experience__viewer__container__item {
	width: 90vw;
	margin-right: 10vw;
	padding-left: 1rem;
	padding-bottom: 1rem;
}

.experience__viewer__container__item a {
	font-weight: 600;
}
.experience__viewer__container__item strong {
	font-weight: 600;
}

@media (min-width: $lg) {
	.experience {
		margin: 10vh 0;
	}
	.experience__title {
		width: 80vw;
		margin-left: 20vw;
		padding-left: 15px;
		text-align: start;
	}

	.experience__lg-container {
		display: grid;
		margin: 0 0vw 0 20vw;
		grid-template-columns: minmax(140px, 15vw) minmax(auto, 65vw);
		grid-template-areas: " header viewer";
	}

	.experience__header {
		display: block;
	}

	.experience__viewer {
		width: 100%;
		border-bottom: 5px solid;
	}

	.experience__viewer__container {
		transition-duration: 0.1s;
		transition-delay: 2s;
	}
	.experience__viewer[courtain="1"] {
		animation: courtain 4s;
	}

	.experience__viewer__container__item {
		width: 55vw;
		margin-right: 45vw;
		overflow: hidden;
	}

	.experience__viewer__container__item p {
		margin-block-start: 5px;
	}
	.experience__viewer__container__item ul {
		margin-block-end: 1rem;
	}
	.experience__viewer__container__item li {
		margin-block-start: 5px;
	}

	@keyframes courtain {
		0% {
			height: 100%;
		}
		40% {
			height: 0%;
		}
		60% {
			height: 0%;
		}
		100% {
			height: 100%;
		}
	}
}

//PROJECTS

.projects {
	width: 100%;
	font-family: $text-font;
	font-size: 0.8rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.projects__title {
	font-family: $title-font;
	text-transform: uppercase;
	width: 100%;
	padding: 10px 0;
	text-align: center;
}

// .projects__container {
// 	overflow: hidden;
// }

.project {
	margin: 5vw;
	padding: 10px;
}

.project__pic {
	height: 200px;
}

.project__pic img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.project__text__title {
	font-family: $title-font;
	font-size: 1rem;
	text-transform: uppercase;
}

.project__text__stack {
	padding-left: 5px;
	font-weight: 600;
	text-transform: uppercase;
}

.project__text__stack__item::after {
	content: " > ";
	color: white;
	height: 100%;
}

.project__text__stack__item:last-child::after {
	content: "";
	color: white;
	height: 100%;
}

.project__text__link {
	text-transform: uppercase;
	margin-right: 1rem;
	font-weight: 600;
}

@media (min-width: $lg) {
	.projects {
		margin: 10vh 0;
	}

	.projects__title {
		width: 80vw;
		margin-right: 20vw;
		padding-right: 15px;
		text-align: end;
	}

	.project {
		margin: 10vh 0;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		align-items: center;
	}

	.project__pic {
		grid-column: 1 / span 7;
		grid-row: 1/1;
		height: 500px;
	}

	.project__text {
		grid-column: 5 / span 5;
		grid-row: 1/1;
		margin: 75px 0;
		z-index: 1;
		padding: 1rem;
		background-color: white;
	}

	.project:nth-of-type(even) .project__pic {
		grid-column: 6 / span 7;
	}

	.project:nth-of-type(even) .project__text {
		grid-column: 3 / span 5;
	}
}

//ABOUT

.about {
	width: 100%;
	font-family: $text-font;
	font-weight: 200;
	font-size: 0.8rem;
	margin-bottom: 3rem;
}

.about__pic {
	padding: 8px;

	width: 100%;
	max-width: 80vh;
	filter: grayscale(100%);
}

.about__pic:hover {
	filter: grayscale(60%);
}

.about__pic img {
	width: 100%;
}
.about__background {
	display: none;
}

.about__title {
	text-align: center;
	font-family: $title-font;
	font-weight: 400;
	font-size: 1rem;
	padding: 5px;

	line-height: 1.5rem;
}
.about__title__span {
	display: block;
}

.about__title__span--dmd {
	text-transform: uppercase;
}

.about__text {
	padding: 8px;
	margin: 0 5px;
	white-space: normal;
}

@media (min-width: $lg) {
	.about {
		display: grid;
		grid-template-columns: 15vw 20vw 30vw 20vw 15vw;
		padding-top: 30px;
	}

	.about__pic {
		grid-column-start: 3;
		grid-row-start: 1;
		z-index: 5;
		margin: 0px;
		padding: 0;
	}
	.about__background {
		display: block;
		grid-column-start: 1;
		grid-column-end: span 3;
		grid-row-start: 1;
		width: 100%;
		height: 100%;
		background: url(./pattern-white.svg) repeat;
		background-size: 30px;
		z-index: 3;
		transform: translateX(-20px) translateY(20px);
	}

	.about__title__container {
		grid-column-start: 3;
		grid-column-end: span 3;
		grid-row-start: 2;
		padding: 0.5rem 0;
		text-align: start;
		margin-top: 3rem;
	}
	.about__title {
		text-align: start;
	}

	.about__title__span {
		display: inline;
		text-transform: uppercase;
	}

	.about__text {
		grid-column-start: 2;
		grid-column-end: span 3;
		grid-row-start: 4;
		text-align: start;
	}
}

// =================
// THEMES
// =================

//LIGHT THEME

body.light {
	font-weight: 200;

	.fake-background {
		background-color: $white;
	}
	.nav {
		background-color: $total-black;
		color: $white;
	}
	.nav__link:hover,
	.nav__link:active {
		color: $main-red;
	}
	.nav__link--disabled,
	.nav__link--disabled:hover,
	.nav__link--disabled:active {
		color: $medium-grey;
		cursor: default;
	}
	.nav__sidebar {
		background-color: $total-black;
		color: $white;
	}

	.footer {
		background-color: $total-black;
		color: $white;
	}

	.intro__hero__item {
		fill: $total-black;
	}
	.intro__description__text {
		background-color: white;
	}
	.intro__description__link:hover,
	.intro__description__link:active {
		color: $main-red;
	}
	@media (min-width: $lg) {
		.intro__hero__text {
			background-color: $total-black;
			color: white;
		}
		.intro__description__background {
			background-color: $light-grey;
		}
	}
	.experience__title {
		color: white;
		background-color: $medium-grey;
	}
	.experience__header__item:hover,
	.experience__header__item:active {
		color: $main-red;
	}
	.experience__header__item--active::before {
		content: "> ";
		color: $main-red;
	}
	.experience__viewer__container__item a:hover,
	.experience__viewer__container__item a:active {
		color: $main-red;
	}

	@media (min-width: $lg) {
		.experience__viewer {
			border-bottom-color: $total-black;
		}
	}
	.projects__title {
		color: white;
		background-color: $medium-grey;
	}
	.project img {
		filter: grayscale(100%);
	}
	.project:hover img {
		filter: grayscale(20%);
	}
	.project__text__stack {
		background-color: $light-grey;
	}
	.project__text__link:hover,
	.project__text__link:active {
		color: $main-red;
	}
	.project__text {
		background-color: white;
	}
	.footer__link:hover,
	.footer__link:active {
		color: $main-red;
	}

	.about__background {
		background-color: $light-grey;
	}

	.about__title {
		background-color: $total-black;
		color: $white;
	}
}

//DARK THEME
body.dark {
	color: $off-white;
	font-weight: 300;
	.fake-background {
		background-color: $total-black;
	}
	.nav {
		background-color: $off-black;
		color: $off-white;
	}
	.nav__link:hover,
	.nav__link:active {
		color: $light-red;
	}
	.nav__link--disabled,
	.nav__link--disabled:hover,
	.nav__link--disabled:active {
		color: $medium-grey;
		cursor: default;
	}
	.nav__sidebar {
		background-color: $off-black;
		color: $off-white;
	}
	.footer {
		background-color: $off-black;
		color: $off-white;
	}

	.intro__hero__item {
		fill: $off-white;
	}
	.intro__description__text {
		background-color: $off-black;
		color: $off-white;
		font-weight: 300;
	}
	.intro__description__link:hover,
	.intro__description__link:active {
		color: $light-red;
	}

	@media (min-width: $lg) {
		.intro__hero__text {
			background-color: $medium-grey;
			color: $off-white;
		}
		.intro__description__background {
			background-color: $medium-grey;
		}
	}
	.experience__title {
		color: $off-white;
		background-color: $medium-grey;
	}
	.experience__header__item:hover,
	.experience__header__item:active {
		color: $light-red;
	}
	.experience__header__item--active::before {
		content: "> ";
		color: $light-red;
	}
	.experience__viewer__container__item a:hover,
	.experience__viewer__container__item a:active {
		color: $light-red;
	}
	@media (min-width: $lg) {
		.experience__viewer {
			border-bottom-color: $medium-grey;
		}
	}
	.projects__title {
		color: white;
		background-color: $medium-grey;
	}
	.project img {
		filter: grayscale(100%) brightness(70%);
	}

	.project:hover img {
		filter: grayscale(20%) brightness(85%);
	}
	.project__text__stack {
		background-color: $medium-grey;
	}
	.project__text__link:hover,
	.project__text__link:active {
		color: $light-red;
	}
	.project__text {
		background-color: $off-black;
	}
	.footer__link:hover,
	.footer__link:active {
		color: $light-red;
	}
	.about__background {
		background-color: $medium-grey;
	}

	.about__title {
		background-color: $medium-grey;
		color: $white;
	}
}
